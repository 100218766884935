'use strict';

/*(function () {
  /!*let ymap = document.querySelector('.js-ymap');
  let iframe = ymap.contentWindow.document;
  iframe.open();
  // iframe.write('TESST');
  iframe.close();*!/


  // ymap.addEventListener(`load`, function () {
    console.log();
  // })

  $(function() {
    var $iframe = $('.js-ymap');
    $iframe.ready(function() {
        $iframe.contents().find("body").css('filter', 'grayscale(1)');
    });
});
})();*/

// Зацикливание фокуса для доступности в модалках
(function () {
  const
    MODAL_ELEMENT_FOCUSED = window.utils.focusedElement,
    MODAL_FOCUS_TRAP_CLASS_NAME = 'js-trap-focus';

  function setFocusTrap(focusWrap) {
    let
      focusedElements = focusWrap.querySelectorAll(MODAL_ELEMENT_FOCUSED),
      firstFocusElement = focusedElements[0],
      lastFocusElement = focusedElements[focusedElements.length - 1],
      defaultFocusElements = focusWrap.querySelectorAll('.' + MODAL_FOCUS_TRAP_CLASS_NAME);

    if (defaultFocusElements.length === 0 && focusedElements.length !== 0) {
      let
        createFocusTrap = window.utils.createDivWithClass(MODAL_FOCUS_TRAP_CLASS_NAME),
        cloneCreateFocusTrap = createFocusTrap.cloneNode();

      cloneCreateFocusTrap.setAttribute('tabindex', '0');
      createFocusTrap.setAttribute('tabindex', '0');

      focusWrap.appendChild(createFocusTrap);
      focusWrap.insertAdjacentElement('afterbegin', cloneCreateFocusTrap);

      // Зацикливание фокуса внутри модалки
      let focusTraps = focusWrap.children;

      if (!lastFocusElement) {
        lastFocusElement = focusTraps[focusTraps.length - 1];
      }
      if (!firstFocusElement) {
        firstFocusElement = focusTraps[0];
      }

      firstFocusElement.focus();

      focusTraps[focusTraps.length - 1].addEventListener('focus', function () {
        firstFocusElement.focus();
      });

      focusTraps[0].addEventListener('focus', function () {
        lastFocusElement.focus();
      });
    } else {
      defaultFocusElements.forEach(function (current) {
        current.remove();
      })
    }

  }

  window.focusTrap = {
    setFocusTrap: setFocusTrap
  }

})();

// Добавление затемнения под модалки
(function () {
  const OVERLAY_CLASS_NAME = 'modal-overlay';

  let createOverModal = window.utils.createDivWithClass(OVERLAY_CLASS_NAME);

  let addOverlayWindow = function (time) {
    document.body.appendChild(createOverModal);

    let modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME);

    setTimeout(function () {
      modalOverlay.classList.add('this-show');
    }, time)
  };

  let removeOverlayWindow = function (time) {
    let
      duration = (time) ? time : 0,
      modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME);

    modalOverlay.classList.remove('this-show');

    setTimeout(function () {
      modalOverlay.remove();
    }, duration)
  };

  window.overlay = {
    add: addOverlayWindow,
    remove: removeOverlayWindow
  }

})();

// Модальное окно
(function () {
  const
    MODAL_BUTTON_CLOSE_CLASS_NAME = 'js-modal-close',
    TRANSITION_TIME = 0.4;

  let alexModal = function (modal) {
    let
      buttonModalShow = modal,
      modalWindow = document.querySelector(buttonModalShow.dataset.id),
      buttonModalHide = modalWindow.querySelectorAll('.' + MODAL_BUTTON_CLOSE_CLASS_NAME),
      body = document.body;

    function openModal() {
      let scrollBarWidth = window.utils.getScrollbarWidth();

      body.classList.add('modal-open');

      document.documentElement.style.setProperty('--scroll-width', scrollBarWidth + 'px');
      modalWindow.style.paddingRight = scrollBarWidth + 'px';

      modalWindow.style.display = 'block';
      setTimeout(function () {
        modalWindow.classList.remove('modal-hidden');
      }, 100);

      window.overlay.add(100);

      document.addEventListener('keydown', documentKeyDownHandler);
      modalWindow.addEventListener('click', modalWindowClickHandler);

      // Доступность для модалк
      modalWindow.removeAttribute('aria-hidden');

      window.focusTrap.setFocusTrap(modalWindow);

    }

    function closeModal() {
      modalWindow.classList.add('modal-hidden');

      // Вешаю отсчёт времени для отработки CSS анимации
      setTimeout(function () {

        body.classList.remove('modal-open');
        document.documentElement.style.setProperty('--scroll-width', '0');

        modalWindow.style.display = 'none';
        modalWindow.style.paddingRight = '0';
      }, TRANSITION_TIME * 1000);

      window.overlay.remove(TRANSITION_TIME * 1000);

      // Доступность для модалк
      modalWindow.setAttribute('aria-hidden', 'true');

      // Убираю все события после закрытия модалки
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.removeEventListener('click', closeModal);
        });
      }
      modalWindow.removeEventListener('click', modalWindowClickHandler);
      document.removeEventListener('keydown', documentKeyDownHandler);

      window.focusTrap.setFocusTrap(modalWindow);

      buttonModalShow.focus();
    }

    function documentKeyDownHandler(evt) {
      if (evt.key === window.utils.keyEscape && !evt.target.matches('input, textarea, select')) {
        closeModal();
        // console.log(evt);
      }
    }

    function modalWindowClickHandler(evt) {
      if (evt.target === modalWindow) {
        closeModal();
      }
    }

    buttonModalShow.addEventListener('click', function (evt) {
      evt.preventDefault();
      openModal();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.addEventListener('click', closeModal);
        });
      }
    });

    buttonModalShow.addEventListener('keydown', function (evt) {
      if (evt.key === window.utils.keyEnter && !buttonModalShow.matches('button, a')) {
        evt.preventDefault();
        openModal();
        if (buttonModalHide) {
          buttonModalHide.forEach(function (current) {
            current.addEventListener('click', closeModal);
          });
        }
      }
    });

    if (modalWindow.classList.contains('open')) {
      openModal();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.addEventListener('click', closeModal);
        });
      }
    }
  };

  window.modal = {
    alexModal: alexModal
  }

})();

// Раскрывающиеся блоки
(function () {
  const
    WINDOW_BUTTON_CLOSE_CLASS_NAME = 'js-expanded-close',
    OVERLAY_CLASS_NAME = 'expanded-overlay',
    BUTTON_EXPANDED_CLASS_NAME = 'button-expand',
    BOX_EXPANDED_CLASS_NAME = 'box-expand',
    TRANSITION_DURATION = 300;

  let createOverModal = window.utils.createDivWithClass(OVERLAY_CLASS_NAME);

  document.body.appendChild(createOverModal);

  let alexExpanded = function (expanded) {
    let
      buttonExpandedShow = expanded,
      expandWindow = document.querySelector(buttonExpandedShow.dataset.id),
      buttonExpandedHide = expandWindow.querySelectorAll('.' + WINDOW_BUTTON_CLOSE_CLASS_NAME),
      ariaLabel = buttonExpandedShow.hasAttribute('aria-label'),
      closeText = buttonExpandedShow.getAttribute('aria-label'),
      openText = buttonExpandedShow.dataset.ariaLabel;

    function expandedExpand() {
      // Добавляю классы
      expandWindow.classList.add(BOX_EXPANDED_CLASS_NAME);
      buttonExpandedShow.classList.add(BUTTON_EXPANDED_CLASS_NAME);

      // Событие на окне для кнопки ESCAPE
      document.addEventListener('keydown', documentKeyDownHandler);

      // Доступность
      expandWindow.setAttribute('aria-expanded', 'false');

      if (ariaLabel) {
        buttonExpandedShow.setAttribute('aria-label', openText);
      }

      // Зацикливание фокуса
      // window.focusTrap.setFocusTrap(expandWindow);
      if (expandWindow.querySelectorAll(window.utils.focusedElement).length > 0) {
        setTimeout(function () {
          expandWindow.querySelectorAll(window.utils.focusedElement)[0].focus();
        }, TRANSITION_DURATION);
      }

      let modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME);

      // Проверка на количество открытых элементов
      if (document.querySelectorAll('.' + BOX_EXPANDED_CLASS_NAME).length > 0) {
        // Добавление класса к затемнению
        modalOverlay.classList.add('this-show');
      }

      // Событие клика на фоне
      modalOverlay.addEventListener('click', expandedCollapse);

      buttonExpandedShow.removeEventListener('click', buttonExpandedShowClickHandler);
      buttonExpandedShow.addEventListener('click', expandedCollapse);
    }

    function expandedCollapse() {
      // Удаление классов
      expandWindow.classList.remove(BOX_EXPANDED_CLASS_NAME);
      buttonExpandedShow.classList.remove(BUTTON_EXPANDED_CLASS_NAME);

      let modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME);

      // Проверка на количество открытых элементов
      if (document.querySelectorAll('.' + BOX_EXPANDED_CLASS_NAME).length === 0) {
        // Удаление класса c затемнения
        modalOverlay.classList.remove('this-show');
      }

      // Удаление события клика на фоне
      modalOverlay.removeEventListener('click', expandedCollapse);

      // Доступность
      expandWindow.setAttribute('aria-expanded', 'true');

      if (ariaLabel) {
        buttonExpandedShow.setAttribute('aria-label', closeText);
      }

      // Убираю все события с кнопки закрытия
      if (buttonExpandedHide) {
        buttonExpandedHide.forEach(function (current) {
          current.removeEventListener('click', expandedCollapse);
        });
      }

      // Удаление события на окне для кнопки ESCAPE
      document.removeEventListener('keydown', documentKeyDownHandler);

      // Возврат фокуса на кнопку открытия
      buttonExpandedShow.focus();

      buttonExpandedShow.removeEventListener('click', expandedCollapse);
      buttonExpandedShow.addEventListener('click', buttonExpandedShowClickHandler);
    }

    function documentKeyDownHandler(evt) {
      if (evt.key === window.utils.keyEscape && !evt.target.matches('input, textarea, select')) {
        expandedCollapse();
      }
    }

    function buttonExpandedShowClickHandler(evt) {
      // Вешает preventDefault если элемент ссылка
      if (buttonExpandedShow.tagName === 'A') {
        evt.preventDefault();
      }

      expandedExpand();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonExpandedHide) {
        buttonExpandedHide.forEach(function (current) {
          current.addEventListener('click', expandedCollapse);
        });
      }
    }

    buttonExpandedShow.addEventListener('click', buttonExpandedShowClickHandler);

    buttonExpandedShow.addEventListener('keydown', function (evt) {
      if (evt.key === window.utils.keyEnter && !buttonExpandedShow.matches('button, a')) {
        // Вешает preventDefault если элемент ссылка
        if (buttonExpandedShow.tagName === 'A') {
          evt.preventDefault();
        }

        expandedExpand();
        if (buttonExpandedHide) {
          buttonExpandedHide.forEach(function (current) {
            current.addEventListener('click', expandedCollapse);
          });
        }
      }
    });

    if (expandWindow.classList.contains('open')) {
      expandedExpand();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonExpandedHide) {
        buttonExpandedHide.forEach(function (current) {
          current.addEventListener('click', expandedCollapse);
        });
      }
    }
  };

  window.expanded = {
    expand: alexExpanded
  }
})();

// Дефолтный поток
(function () {

  AOS.init({disable: 'mobile'});

  if (document.querySelectorAll('.js-modal').length > 0) {
    document.querySelectorAll('.js-modal').forEach(function (current) {
      window.modal.alexModal(current);
    });
  }

  if (document.querySelectorAll('.js-expanded').length > 0) {
    document.querySelectorAll('.js-expanded').forEach(function (current) {
      window.expanded.expand(current);
    });
  }

  document.querySelectorAll('.js-tab-parent').forEach(function (current) {
    window.tab.tabWork(current);
  });
})();
